import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  FormControlLabel,
  MenuItem,
  Select,
  Chip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ImageIcon from '@mui/icons-material/Image';
import { useGetProductImagesMutation, useGetProductsQuery, useDeleteProductMutation, useCreateProductMutation, useUpdateProductMutation, useGetCategoryQuery, useCreateProductQuestionsMutation, useGetProductQuestionsMutation, useDeleteProductQuestionMutation,useCreateProductWinningMutation,useGetProductWinningMutation, useDeleteProductWinningMutation } from "state/api";
import { Header, ImageDialog } from "components";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Products = () => {
  const { data, isLoading, refetch } = useGetProductsQuery();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const [open, setOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const { data: categoryData, isLoading: categoryLoading } = useGetCategoryQuery();
  const [productId, setProductId] = useState(); // Images for the product
  const [deleteProduct, { isLoading: LoadingDelete }] = useDeleteProductMutation();
  const [createProduct, { isLoading: LoadingCreate }] = useCreateProductMutation();
  const [updateProduct, { isLoading: LoadingUpdate }] = useUpdateProductMutation();
  const [productImages, { isLoading: LoadingImages }] = useGetProductImagesMutation();
  const [productQuestions, { isLoading: LoadingProductQuestionLoading }] = useGetProductQuestionsMutation();
  const [productWinnings, { isLoading: LoadingProductWinnings }] = useGetProductWinningMutation();
  const [createProductQuestion, { isLoading: LoadingProuctQuestionCreate }] = useCreateProductQuestionsMutation();
  const [deleteProductQuestion, { isLoading: LoadingProductQuestionDelete }] = useDeleteProductQuestionMutation();
  const [createProductWinning, { isLoading: LoadingProductWinning }] = useCreateProductWinningMutation();
  const [deleteProductWinning, { isLoading: LoadingProductDeleteWinning }] = useDeleteProductWinningMutation();
  

  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [terms, setTerms] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [productType, setProductType] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [totalWish, setTotalWish] = useState("");
  const [deductingWish, setDeductingWish] = useState("");
  const [totalQuestions, setTotalQuestions] = useState("");
  const [status, setStatus] = useState(false);
  const [isFirstWinnerGotProduct, setIsFirstWinnerGotProduct] = useState("");
  const [imageName, setImageName] = useState('');
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [imagesData, setImagesData] = useState([]);
  const [productAllQuestions, setProductAllQuestions] = useState([]);
  const [productAllWinning, setProductAllWinning] = useState([]);
  const [totalWishCount, setTotalWishCount] = useState([]);
  const [isPlayable, setIsPlayable] = useState("");

  const [isTimeBasedEvent, setIsTimeBasedEvent] = useState("0");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  

  const [questionTypes, setQuestionTypes] = useState([]);
  const [questionDialogOpen, setQuestionDialogOpen] = useState(false);

  const [wishDialogOpen, setWishDialogOpen] = useState(false);
  const [wishRanges, setWishRanges] = useState([{ range: '', percentage: '', amount: '' }]);
  const [productQuestionCount,setProductQuestionCount] = useState("");

  useEffect(() => {
    const updatedWishRanges = wishRanges.map(wish => ({
      ...wish,
      amount: (productPrice * (parseFloat(wish.percentage || 0) / 100)).toFixed(2)
    }));
    setWishRanges(updatedWishRanges);
  }, []);

  const handleWishDialogOpen = async (productId, price, wishCount) => {
    const allWinnings = await productWinnings(productId);
    setProductAllWinning(allWinnings.data);
    setWishDialogOpen(true);
    setProductId(productId);
    setProductPrice(price);
    setTotalWishCount(wishCount);
  };

  const handleWishDialogClose = () => {
    setWishDialogOpen(false);
  };

  const handleAddWishRange = () => {
    setWishRanges([...wishRanges, { range: '', percentage: '', amount: 2000 }]);
  };

  const handleRemoveWishRange = (index) => {
    const newWishRanges = wishRanges.filter((_, i) => i !== index);
    setWishRanges(newWishRanges);
  };

  const handleWishRangeChange = (index, field, value) => {
    const newWishRanges = wishRanges.map((wishRange, i) =>
      i === index ? { ...wishRange, [field]: value, amount: field === 'percentage' ? (productPrice * (parseFloat(value || 0) / 100)).toFixed(2) : wishRange.amount } : wishRange
    );
    setWishRanges(newWishRanges);
  };

  const handleUpdateWish = async () => {
    let totalPercentage = 0;
    wishRanges.forEach(wish => {
      totalPercentage += parseFloat(wish.percentage);
    });

    if (totalPercentage !== 100) {
      // toast.error('Total percentage must equal 100%', {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
      // return;
    }

    const distributedWishes = wishRanges.map(wish => ({
      range: wish.range,
      amount: (productPrice * (parseFloat(wish.percentage) / 100)).toFixed(2)
    }));

    // console.log(distributedWishes);
    // Logic to handle distributed wishes (e.g., save to backend)

    if (distributedWishes.length > 0) {
      for (let i = 0; i < distributedWishes.length; i++) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
       await createProductWinning({ winrange: distributedWishes[i]["range"], productId: productId, winpercent: wishRanges[i]["percentage"],amount: distributedWishes[i]["amount"] });
      }
    }

    
    toast.success('Wishes updated successfully!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    const allWinnings = await productWinnings(productId);
    setProductAllWinning(allWinnings.data);
  };

  const handleQuestionDialogOpen = async (productId,totalQuestions) => {
    setProductAllQuestions([]);
    const allQuestions = await productQuestions(productId);
    setProductAllQuestions(allQuestions.data);
    setProductId(productId);
    setProductQuestionCount(totalQuestions);
    setQuestionDialogOpen(true);
  };

  const handleQuestionDialogClose = () => {
    setQuestionDialogOpen(false);
  };

  const handleAddQuestionType = () => {
    setQuestionTypes([...questionTypes, { type: '', count: '', id: '' }]);
  };

  const handleRemoveQuestionType = (index) => {
    const newQuestionTypes = questionTypes.filter((_, i) => i !== index);
    setQuestionTypes(newQuestionTypes);
  };

  const handleQuestionTypeChange = (index, field, value) => {
    const newQuestionTypes = questionTypes.map((questionType, i) =>
      i === index ? { ...questionType, [field]: value } : questionType
    );
    setQuestionTypes(newQuestionTypes);
  };

  const handleUpdateQuestion = async () => {
    // console.log(questionTypes);
    if (questionTypes.length > 0) {
      for (let i = 0; i < questionTypes.length; i++) {
        await createProductQuestion({ questionFormat: questionTypes[i]["type"], productId: productId, questionCount: questionTypes[i]["count"] });

      }
    }
    toast.success('Question add to this product  successfully!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setQuestionTypes([]);
    const allQuestions = await productQuestions(productId);
    setProductAllQuestions(allQuestions.data);
  }

  const handleDeleteQuestion = async (id) => {
    console.log(id);
    await deleteProductQuestion(id);
    toast.success('Question Delete  successfully!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    const allQuestions = await productQuestions(productId);
    setProductAllQuestions(allQuestions.data);
  }

  const handleDeleteProductWinning = async (id) => {
    console.log(id);
    await deleteProductWinning(id);
    toast.success('Product Winning Delete successfully!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    const allWinnings = await productWinnings(productId);
    setProductAllWinning(allWinnings.data);
  }

  

  const [imageDialogOpen, setImageDialogOpen] = useState(false);

  const handleImageDialogOpen = async (productId) => {
    const imageData = await productImages(productId);
    setImagesData(imageData.data);
    setProductId(productId);
    setImageDialogOpen(true);
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false);
  };

  const handleChange = (event) => {
    setCategoryId(event.target.value);
  };

  const handleProductTypeChange = (event) => {
    setProductType(event.target.value);
  };

  const handleProductEnableChange = (event) => {
    setIsPlayable(event.target.value);
  };

  const handleTimeBasedEventChange = (event) => {
    setIsTimeBasedEvent(event.target.value);
  };

  

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    if (e.target.files && e.target.files[0]) {
      setImageName(e.target.files[0].name);
    }
  };

  const handleDelete = (id) => {
    setSelectedProductId(id);
    setOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteProduct(selectedProductId);
      toast.success('Product Deleted!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("Error deleting product:", error);
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const handleCreateDialogOpen = () => {
    setOpenCreateDialog(true);
  };
  const handleCreateDialogClose = () => {
    setOpenCreateDialog(false);
  };

  const handleCreateProduct = async () => {
    try {
      await createProduct({
        productName,
        productDescription,
        terms,
        categoryId,
        productPrice,
        totalWish,
        totalQuestions,
        status,
        image: selectedImage,
        deductingWish,
        productType,
        isFirstWinnerGotProduct,
        isPlayable,
        isTimeBasedEvent,
        startDateTime,
        endDateTime,
      });
      console.log("Product created successfully!");
    } catch (error) {
      console.error("Error creating product:", error);
    }
    refetch();
    setOpenCreateDialog(false);
  };

  const handleEdit = (id) => {
    const product = data.find(prod => prod._id === id);
    if (product) {
      setEditingProduct(product);
      setProductName(product.productName);
      setProductDescription(product.productDescription);
      setTerms(product.terms);
      setCategoryId(product.categoryId);
      setProductPrice(product.productPrice);
      setTotalWish(product.totalWish);
      setTotalQuestions(product.totalQuestions);
      setDeductingWish(product.deductingWish);
      setStatus(product.status === 'ACTIVE');
      setProductType(product.productType);
      setIsFirstWinnerGotProduct(product.isFirstWinnerGotProduct);
      setDeductingWish(product.deductingWish);
      setImageName(product.image);
      setIsPlayable(product.isPlayable);
      setIsTimeBasedEvent(product.isTimeBasedEvent === true ? "1" : "0");
      setStartDateTime(convertToISTSimple(product.startDateTime));
      setEndDateTime(convertToISTSimple(product.endDateTime));
      setEditDialogOpen(true);
      
    }
  };

  function convertToISTSimple(input) {
    // Parse the input UTC date
    const utcDate = new Date(input);
  
    // IST offset: UTC + 5 hours 30 minutes
    const IST_OFFSET = 5.5 * 60 * 60 * 1000; // Convert hours to milliseconds
  
    // Convert to IST
    const istDate = new Date(utcDate.getTime() + IST_OFFSET);
  
    // Format as `YYYY-MM-DDTHH:mm`
    const year = istDate.getFullYear();
    const month = String(istDate.getMonth() + 1).padStart(2, "0");
    const day = String(istDate.getDate()).padStart(2, "0");
    const hours = String(istDate.getHours()).padStart(2, "0");
    const minutes = String(istDate.getMinutes()).padStart(2, "0");
  
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setEditingProduct(null);
    setProductName("");
    setProductDescription("");
    setTerms("");
    setCategoryId("");
    setProductPrice("");
    setTotalWish("");
    setTotalQuestions("");
    setIsPlayable("");
    setStatus(false);
    setIsFirstWinnerGotProduct("");
    setSelectedImage(null);
    setImageName("");
    setIsTimeBasedEvent("");
  };

  const handleUpdateProduct = async () => {
    try {
      await updateProduct({
        _id: editingProduct._id,
        productName,
        productDescription,
        terms,
        categoryId,
        productPrice,
        totalWish,
        totalQuestions,
        status: status ? 'ACTIVE' : 'INACTIVE',
        image: selectedImage || editingProduct.image,
        productType,
        deductingWish,
        isFirstWinnerGotProduct : isFirstWinnerGotProduct,
        isPlayable,
        isTimeBasedEvent,
        startDateTime,
        endDateTime,
      });
      console.log("Product updated successfully!");
      handleEditClose();
      refetch();
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const calculateTotalPercentage = () => {
    return wishRanges.reduce((total, wish) => total + parseFloat(wish.percentage || 0), 0);
  };

  const calculatePendingAmount = () => {
    const totalAllocatedAmount = wishRanges.reduce((total, wish) => total + parseFloat(wish.amount || 0), 0);
    return productPrice - totalAllocatedAmount;
  };

  return (
    <Box m="1.5rem 2.5rem">
      <ToastContainer position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header title="Product" subtitle="See your list of products." />
      <Box display="flex" justifyContent="flex-end" m="1.5rem 2.5rem">
        <Button variant="contained" color="primary" onClick={handleCreateDialogOpen}>
          Create Product
        </Button>
      </Box>
      {data || !isLoading ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sr.No</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(({ _id, productName, image, productDescription, category, productPrice,totalWish,totalQuestions,productType, isPlayable }, index) => (
                <TableRow key={_id} csx={{
                  backgroundColor: isPlayable == "YES" ? 'green' : 'red',
                }}
>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{productName}
                   <div>
                  {
                    productType == "FREE_ENTRY" ?  <Chip 
                    label="FREE ENTRY" 
                    style={{ backgroundColor: 'red', color: 'white' }} 
                  /> :  <Chip 
                  label="PAID ENTRY" 
                  style={{ backgroundColor: 'green', color: 'white' }} 
                /> 
                  }
                   </div>

                  </TableCell>
                  <TableCell> {
                    isPlayable == "YES" ?  <Chip 
                    label="PLAYER PLAYING" 
                    style={{ backgroundColor: 'green', color: 'white' }} 
                  /> :  <Chip 
                  label="DISABLED" 
                  style={{ backgroundColor: 'red', color: 'white' }} 
                /> 
                  }</TableCell>
                  <TableCell>{category.name}</TableCell>
                  <TableCell>
  {productDescription.length > 50
    ? `${productDescription.substring(0, 50)}...`
    : productDescription}
</TableCell>
                  <TableCell>
                    <Box display="flex" gap={1}>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleEdit(_id)}
                        sx={{ color: "green", borderColor: "green" }}
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleDelete(_id)}
                        sx={{ color: "red", borderColor: "red" }}
                        startIcon={<DeleteIcon />}
                      >
                        Delete
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleImageDialogOpen(_id)}
                        sx={{ color: "blue", borderColor: "blue" }}
                        startIcon={<ImageIcon />}
                      >
                        Images
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleQuestionDialogOpen(_id,totalQuestions)}
                        sx={{ color: "purple", borderColor: "purple" }}
                      >
                        Questions
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleWishDialogOpen(_id, productPrice,totalWish)}
                        sx={{ color: "orange", borderColor: "orange" }}
                      >
                        Winnings
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="h5" mt="20%" textAlign="center">
          Loading...
        </Typography>
      )}

      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" sx={{ backgroundColor: 'grey', color: 'white', '&:hover': { color: 'grey', backgroundColor: 'white', border: 'red' } }}>
            Cancel
          </Button>
          <LoadingButton onClick={confirmDelete} color="primary" autoFocus loading={LoadingDelete} sx={{ backgroundColor: 'red', color: 'white', '&:hover': { color: 'red', backgroundColor: 'white', border: 'red' } }}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openCreateDialog} onClose={handleCreateDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Product</DialogTitle>
        <DialogContent>
           <h3>Please Select Entry Type </h3>
            <Select
                labelId="demo-simple-select-label"
              
                id="categoryId"
                name="categoryId"
                label="Product Type"
                fullWidth
                variant="outlined"
                onChange={handleProductTypeChange}
              >
                <MenuItem value="">
                  <em>Select Product Entry Type</em>
                </MenuItem>
                <MenuItem value="FREE_ENTRY">
                  <em>Free Entry</em>
                </MenuItem>
                <MenuItem value="PAID_ENTRY">
                  <em>Paid Entry</em>
                </MenuItem>
               
              </Select>

            <h3>Please Select 1st rank winner Type </h3>

            <Select
                labelId="demo-simple-select-label"
                id="productOrCash"
                name="productOrCash"
                label="Is First Winner Got Product or Cash"
                fullWidth
                
                onChange={(event) => setIsFirstWinnerGotProduct(event.target.value)}
              >
                <MenuItem value="">
                  <em>Select Cash or Product</em>
                </MenuItem>
                <MenuItem value="CASH">
                  <em>CASH</em>
                </MenuItem>
                <MenuItem value="PRODUCT">
                  <em>PRODUCT</em>
                </MenuItem>
              </Select>

              <h3>Enable for Playing ? </h3>
          <Box
              mt="20px"
              display="grid"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              justifyContent="space-between"
              rowGap="20px"
              columnGap="1.33%"
              sx={{
                "& .MuiInputBase-root": {
                  color: "black", // Set the text color to black
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Set the border color to black
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Set the hover border color to black
                },
                "& .MuiSvgIcon-root": {
                  color: "black", // Set the icon color to black
                },
              }}
            >
              <Select
                labelId="demo-simple-select-label"
                value={isPlayable}
                id="isPlayableid"
                name="isPlayableId"
                label="Enable for Playing"
                fullWidth
                variant="outlined"
                onChange={handleProductEnableChange}
              >
                <MenuItem value="">
                  <em>Select Enable for playing</em>
                </MenuItem>
                <MenuItem value="YES">
                  <em>YES</em>
                </MenuItem>
                <MenuItem value="NO">
                  <em>NO</em>
                </MenuItem>
               
              </Select>
            </Box>
            <h3>Is Time Based Event ? </h3>
            <Box
              mt="20px"
              display="grid"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              justifyContent="space-between"
              rowGap="20px"
              columnGap="1.33%"
              sx={{
                "& .MuiInputBase-root": {
                  color: "black", // Set the text color to black
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Set the border color to black
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Set the hover border color to black
                },
                "& .MuiSvgIcon-root": {
                  color: "black", // Set the icon color to black
                },
              }}
            >
            <Select
                labelId="demo-simple-select-label"
                value={isTimeBasedEvent}
                id="isTimeBasedEventID"
                name="isTimeBasedEvent"
                label="Enable for Playing"
                fullWidth
                variant="outlined"
                onChange={handleTimeBasedEventChange}
              >
                <MenuItem value="">
                  <em>Is Time Based Event</em>
                </MenuItem>
                <MenuItem value="1">
                  <em>YES</em>
                </MenuItem>
                <MenuItem value="0">
                  <em>NO</em>
                </MenuItem>
               
              </Select>
            </Box>

            <h3>Start Date time </h3>
            <TextField
              margin="dense"
              id="datetime-picker"
              name="dateTime"
              label="Select Event Start Date and Time"
              type="datetime-local"
              fullWidth
              InputLabelProps={{
                shrink: true, // Ensures the label doesn't overlap the input
              }}
              value={startDateTime}
              onChange={(e) => setStartDateTime(e.target.value)}
            />
            <h3>End Date time ? </h3>
            <TextField
              margin="dense"
              id="datetime-picker"
              name="dateTime"
              label="Select Event End Date and Time"
              type="datetime-local"
              fullWidth
              InputLabelProps={{
                shrink: true, // Ensures the label doesn't overlap the input
              }}
              value={endDateTime}
              onChange={(e) => setEndDateTime(e.target.value)}
            />
          <h3>Product Name </h3> 
         
          <TextField autoFocus margin="dense"
           id="productName" name="productName"
            label="Product Name" fullWidth variant="outlined"
             value={productName} onChange={(e) => setProductName(e.target.value)} />
          {categoryData || !categoryLoading ? (
            <Box
              mt="20px"
              display="grid"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              justifyContent="space-between"
              rowGap="20px"
              columnGap="1.33%"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Select
                labelId="demo-simple-select-label"
                value={categoryId}
                id="categoryId"
                name="categoryId"
                label="Category ID"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>Select Category</em>
                </MenuItem>
                {categoryData.map(({ _id, name }) => (
                  <MenuItem key={_id} value={_id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          ) : (
            <Typography variant="h5" mt="20%" textAlign="center">
              Loading...
            </Typography>
          )}
          <TextField margin="dense" id="productDescription" name="productDescription" label="Product Description" fullWidth variant="outlined" multiline rows={4} value={productDescription} onChange={(e) => setProductDescription(e.target.value)} />
          <TextField margin="dense" id="terms" name="terms" label="Terms" fullWidth variant="outlined" multiline rows={4} value={terms} onChange={(e) => setTerms(e.target.value)} />
          <TextField margin="dense"  type="number"  id="productPrice" name="productPrice" label="Product Price Pool" fullWidth variant="outlined" value={productPrice} onChange={(e) => setProductPrice(e.target.value)} />
         
         
         
          <TextField margin="dense" id="totalWish"  type="number"  name="totalWish" label="Total Wish" fullWidth variant="outlined" value={totalWish} onChange={(e) => setTotalWish(e.target.value)} />
          <TextField margin="dense" 
           type="number" 
          id="deductingwish" 
          name="deductingwish"
          label="Deducting number of Wish" fullWidth variant="outlined"
          value={deductingWish} onChange={(e) => setDeductingWish(e.target.value)} />
         
          <TextField margin="dense"
           type="number" 
           id="totalQuestions"
           name="totalQuestions" 
           label="Total Questions" 
           fullWidth variant="outlined"
            value={totalQuestions} onChange={(e) => setTotalQuestions(e.target.value)} />

          <FormControlLabel control={<Switch checked={status} onChange={(e) => setStatus(e.target.checked)} />} label="Status" />
          
          
          <input accept="image/*" id="image" type="file" name="file" style={{ display: "none" }} onChange={handleImageChange} />
          
          
          <label htmlFor="image">
            <Button variant="outlined" component="span" style={{ color: 'green', borderColor: 'green' }} startIcon={<AddPhotoAlternateIcon />}>
              Select Image
            </Button>
          </label>
          {imageName && <Typography variant="body1">{imageName}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateDialogClose} color="primary" sx={{ backgroundColor: 'grey', color: 'white', '&:hover': { color: 'white', backgroundColor: 'grey', border: 'green' } }}>
            Cancel
          </Button>
          <LoadingButton onClick={handleCreateProduct} color="primary" loading={LoadingCreate} sx={{ backgroundColor: 'green', color: 'white', '&:hover': { color: 'green', backgroundColor: 'white', border: 'green' } }}>
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog open={editDialogOpen} onClose={handleEditClose} aria-labelledby="edit-dialog-title">
        <DialogTitle id="edit-dialog-title">Edit Product</DialogTitle>
        <DialogContent>
            <h3>Please Select Entry Type </h3>
             <Box
              mt="20px"
              display="grid"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              justifyContent="space-between"
              rowGap="20px"
              columnGap="1.33%"
              sx={{
                "& .MuiInputBase-root": {
                  color: "black", // Set the text color to black
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Set the border color to black
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Set the hover border color to black
                },
                "& .MuiSvgIcon-root": {
                  color: "black", // Set the icon color to black
                },
              }}
            >
              <Select
                labelId="demo-simple-select-label"
                value={productType}
                id="categoryId"
                name="categoryId"
                label="Product Type"
                fullWidth
                variant="outlined"
                onChange={handleProductTypeChange}
              >
                <MenuItem value="">
                  <em>Select Product Type</em>
                </MenuItem>
                <MenuItem value="FREE_ENTRY">
                  <em>Free Entry</em>
                </MenuItem>
                <MenuItem value="PAID_ENTRY">
                  <em>Paid Entry</em>
                </MenuItem>
               
              </Select>
            </Box>

            <h3>Please Select 1st rank winner Type </h3>
            <Select
                labelId="demo-simple-select-label"
                id="productOrCash"
                value={isFirstWinnerGotProduct}
                name="productOrCash"
                label="Is First Winner Got Product or Cash"
                fullWidth
                
                onChange={(event) => setIsFirstWinnerGotProduct(event.target.value)}
              >
                <MenuItem value="">
                  <em>Select Cash or Product</em>
                </MenuItem>
                <MenuItem value="CASH">
                  <em>CASH</em>
                </MenuItem>
                <MenuItem value="PRODUCT">
                  <em>PRODUCT</em>
                </MenuItem>
              </Select>

              <h3>Enable for Playing ? </h3>
          <Box
              mt="20px"
              display="grid"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              justifyContent="space-between"
              rowGap="20px"
              columnGap="1.33%"
              sx={{
                "& .MuiInputBase-root": {
                  color: "black", // Set the text color to black
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Set the border color to black
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Set the hover border color to black
                },
                "& .MuiSvgIcon-root": {
                  color: "black", // Set the icon color to black
                },
              }}
            >
              <Select
                labelId="demo-simple-select-label"
                value={isPlayable}
                id="isPlayableid"
                name="isPlayableId"
                label="Enable for Playing"
                fullWidth
                variant="outlined"
                onChange={handleProductEnableChange}
              >
                <MenuItem value="">
                  <em>Select Enable for playing</em>
                </MenuItem>
                <MenuItem value="YES">
                  <em>YES</em>
                </MenuItem>
                <MenuItem value="NO">
                  <em>NO</em>
                </MenuItem>
               
              </Select>
            </Box>
            <h3>Is Time Based Event ? </h3>
            <Box
              mt="20px"
              display="grid"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              justifyContent="space-between"
              rowGap="20px"
              columnGap="1.33%"
              sx={{
                "& .MuiInputBase-root": {
                  color: "black", // Set the text color to black
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Set the border color to black
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Set the hover border color to black
                },
                "& .MuiSvgIcon-root": {
                  color: "black", // Set the icon color to black
                },
              }}
            >
            <Select
                labelId="demo-simple-select-label"
                value={isTimeBasedEvent}
                id="isTimeBasedEventID"
                name="isTimeBasedEvent"
                label="Enable for Playing"
                fullWidth
                variant="outlined"
                onChange={handleTimeBasedEventChange}
              >
                <MenuItem value="">
                  <em>Is Time Based Event</em>
                </MenuItem>
                <MenuItem value="1">
                  <em>YES</em>
                </MenuItem>
                <MenuItem value="0">
                  <em>NO</em>
                </MenuItem>
               
              </Select>
            </Box>
            <h3>Start Date time </h3>
            <TextField
              margin="dense"
              id="datetime-picker"
              name="dateTime"
              label="Select Event Start Date and Time"
              type="datetime-local"
              fullWidth
              InputLabelProps={{
                shrink: true, // Ensures the label doesn't overlap the input
              }}
              value={startDateTime}
              onChange={(e) => setStartDateTime(e.target.value)}
            />
            <h3>End Date time ? </h3>
            <TextField
              margin="dense"
              id="datetime-picker"
              name="dateTime"
              label="Select Event End Date and Time"
              type="datetime-local"
              fullWidth
              InputLabelProps={{
                shrink: true, // Ensures the label doesn't overlap the input
              }}
              value={endDateTime}
              onChange={(e) => setEndDateTime(e.target.value)}
            />
          <h3>Product Name </h3>  
          <TextField autoFocus margin="dense" id="edit-name" name="editName" label="Product Name" fullWidth variant="outlined" value={productName} onChange={(e) => setProductName(e.target.value)} />
          <TextField margin="dense" id="edit-description" name="editDescription" label="Product Description" fullWidth variant="outlined" multiline rows={4} value={productDescription} onChange={(e) => setProductDescription(e.target.value)} />
          <TextField margin="dense" id="edit-terms" name="editTerms" label="Terms" fullWidth variant="outlined" multiline rows={4} value={terms} onChange={(e) => setTerms(e.target.value)} />
          <TextField margin="dense" id="edit-categoryId" name="editCategoryId" label="Category ID" fullWidth variant="outlined" value={categoryId} onChange={(e) => setCategoryId(e.target.value)} />
          <TextField margin="dense" type="number"  id="edit-price" name="editPrice" label="Product Price Pool" fullWidth variant="outlined" value={productPrice} onChange={(e) => setProductPrice(e.target.value)} />
          <TextField margin="dense" type="number"  id="edit-totalWish" name="editTotalWish" label="Total Wish" fullWidth variant="outlined" value={totalWish} onChange={(e) => setTotalWish(e.target.value)} />
          <TextField margin="dense" type="number"  id="edit-totalQuestions" name="editTotalQuestions" label="Total Questions" fullWidth variant="outlined" value={totalQuestions} onChange={(e) => setTotalQuestions(e.target.value)} />
          <TextField margin="dense" type="number"  id="edit-deductingwish" name="editDeductingwish" 
          label="Deducting number of Wish" fullWidth variant="outlined" 
          value={deductingWish} onChange={(e) => setDeductingWish(e.target.value)} />
          
          
          <FormControlLabel control={<Switch checked={status} onChange={(e) => setStatus(e.target.checked)} />} label="Status" />
          {/* <input accept="image/*" id="edit-image" type="file" name="editFile" style={{ display: "none" }} onChange={handleImageChange} />
          <label htmlFor="edit-image">
            <Button variant="outlined" component="span" style={{ color: 'green', borderColor: 'green' }} startIcon={<AddPhotoAlternateIcon />}>
              Select Image
            </Button>
          </label>
          {imageName && <Typography variant="body1">{imageName}</Typography>} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="primary" sx={{ backgroundColor: 'grey', color: 'white', '&:hover': { color: 'white', backgroundColor: 'grey', border: 'green' } }}>
            Cancel
          </Button>
          <LoadingButton onClick={handleUpdateProduct} color="primary" loading={LoadingUpdate} sx={{ backgroundColor: 'green', color: 'white', '&:hover': { color: 'green', backgroundColor: 'white', border: 'green' } }}>
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={questionDialogOpen}
        onClose={handleQuestionDialogClose}
        aria-labelledby="question-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="question-dialog-title">Question Management</DialogTitle>
        <DialogContent>

        
          {productAllQuestions !== null && productAllQuestions.map((question, index) => (
            <Box key={index} display="flex" alignItems="center" mb={2}>
              <Typography variant="body1" style={{ flex: 1 }}>
                (Type : { question.questionFormat}) (Question :  {question.questionCount})
              </Typography>
              <IconButton color="error" onClick={() => handleDeleteQuestion(question._id)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}

          {questionTypes.map((questionType, index) => (
            <Box key={index} display="flex" alignItems="center" mb={2}>
              <Select
                margin="dense"
                color="primary"
                style={{ width: 200 }}
                label="Question Type"
                value={questionType.type}
                onChange={(e) => handleQuestionTypeChange(index, 'type', e.target.value)}
                variant="outlined"
                sx={{ marginRight: 1, minWidth: 120 }}
              >
                <MenuItem value="a+b">a+b</MenuItem>
                <MenuItem value="aa+b">aa+b</MenuItem>
                <MenuItem value="a-b">a-b</MenuItem>
                <MenuItem value="aa-b">aa-b</MenuItem>
                <MenuItem value="a*b">a*b</MenuItem>
                <MenuItem value="aa*b">aa*b</MenuItem>
                <MenuItem value="a/b">a/b</MenuItem>
                <MenuItem value="aa/b">aa/b</MenuItem>
                <MenuItem value="a%b">a%b</MenuItem>
                <MenuItem value="aa%b">aa%b</MenuItem>
              </Select>
              <TextField
                style={{ width: 200 }}
                margin="dense"
                label="Number of Questions"
                variant="outlined"
                value={questionType.count}
                onChange={(e) => handleQuestionTypeChange(index, 'count', e.target.value)}
                sx={{ marginRight: 1 }}
              />
              <IconButton color="error" onClick={() => handleRemoveQuestionType(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button variant="contained" color="success" onClick={handleAddQuestionType} startIcon={<AddPhotoAlternateIcon />}>
            Add Questions
          </Button>

          <Box display="flex" justifyContent="space-between" mt={2}>
          <Typography variant="body1" style={{ flex: 1 }}>
          Note : You can Update Total Qty of Question in Edit Product
    </Typography>
  <Typography variant="body1">
    Total Questions : {productQuestionCount}

  </Typography>
</Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="warning" onClick={handleUpdateQuestion} startIcon={<AddPhotoAlternateIcon />}>
            Update Questions
          </Button>
          <Button onClick={handleQuestionDialogClose} color="error" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={wishDialogOpen}
        onClose={handleWishDialogClose}
        aria-labelledby="wish-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="wish-dialog-title">Winning Management</DialogTitle>
        <DialogContent>
        <Typography variant="h6" align="right">First Price will be  always Product</Typography>
          <Typography variant="h6" align="right">Product Distribute Price: ₹ {productPrice}</Typography>
          <Typography variant="h6" align="right">Total Wish Count: {totalWishCount}</Typography>
          <hr></hr>
          <Box display="flex" justifyContent="space-between" mt={2}>
  <h3 variant="body1">
    Range for this Product
  </h3>
</Box>
          {productAllWinning !== null && productAllWinning.map((question, index) => (
  <Box key={index} display="flex" alignItems="center" mb={2}>
    <Typography variant="body1" style={{ flex: 1 }}>
      (Range: {question.winrange}) (Percent: {question.winpercent}%) (Amount: ₹{question.amount})
    </Typography>
    <IconButton color="error" onClick={() => handleDeleteProductWinning(question._id)}>
      <DeleteIcon />
    </IconButton>
  </Box>
))}

<Box display="flex" justifyContent="space-between" mt={2}>
  <Typography variant="body1">
    Total Percent: {productAllWinning.reduce((total, question) => total + parseFloat(question.winpercent), 0)}%
  </Typography>
  <Typography variant="body1">
    Total Amount: ₹{productAllWinning.reduce((total, question) => total + parseFloat(question.amount), 0).toFixed(2)}
  </Typography>
</Box>

 <hr></hr>
          {wishRanges.map((wishRange, index) => (
            <Box key={index} display="flex" alignItems="center" mb={2}>
              <TextField
                style={{ width: 200 }}
                margin="dense"
                label="Wish Range"
                variant="outlined"
                value={wishRange.range}
                onChange={(e) => handleWishRangeChange(index, 'range', e.target.value)}
                sx={{ marginRight: 1 }}
              />
              <TextField
                style={{ width: 200 }}
                margin="dense"
                label="Percentage"
                variant="outlined"
                value={wishRange.percentage}
                onChange={(e) => handleWishRangeChange(index, 'percentage', e.target.value)}
                sx={{ marginRight: 1 }}
              />
              <Typography variant="body1" style={{ width: 100 }}>
                ₹ {wishRange.amount}
              </Typography>
              <IconButton color="error" onClick={() => handleRemoveWishRange(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button variant="contained" color="success" onClick={handleAddWishRange} startIcon={<AddPhotoAlternateIcon />}>
              Add Winning Range
            </Button>
            <Typography variant="body1">
              Total Percentage: {calculateTotalPercentage()}%
            </Typography>
          </Box>
          <Typography variant="body1" align="right" mt={2}>
            Pending Amount: ₹ {calculatePendingAmount()}
          </Typography>
        </DialogContent>
        <DialogActions>
        {/* <Button onClick={handleWishDialogClose} color="error" variant="contained">
            Delete All winning
          </Button> */}
          
          <Button variant="contained" color="success" onClick={handleUpdateWish} startIcon={<AddPhotoAlternateIcon />}>
            Update Winning
          </Button>
          <Button onClick={handleWishDialogClose} color="info" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <ImageDialog
        open={imageDialogOpen}
        onClose={handleImageDialogClose}
        productId={productId}
        images={imagesData}
      />
    </Box>
  );
};

export default Products;
