import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Helper function to determine the base URL dynamically
const dynamicBaseQuery = async (args, api, extraOptions) => {
  // let baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:4001";
  // let passType = false;
  // if (!args || !args.url) {
  //   baseUrl = "http://localhost:4001";
  //   passType = false;
  // }else{
  //   if (args.url.startsWith("auth")) {
  //     baseUrl = "http://localhost:3001";
  //     passType = true;
  //   }
  // }

  let baseUrl = "http://k8s-default-wishaur-a2c24b92b7-1213354816.ap-south-1.elb.amazonaws.com";
  let passType = false;
  if (!args || !args.url) {
    baseUrl = "http://k8s-default-wishaur-a2c24b92b7-1213354816.ap-south-1.elb.amazonaws.com";
    passType = false;
  }else{
    if (args.url.startsWith("auth")) {
      baseUrl = "http://k8s-default-wishaur-a2c24b92b7-1213354816.ap-south-1.elb.amazonaws.com";
      passType = true;
    }
  }
 
  const token = localStorage.getItem('authToken');
  const rawBaseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      passType && headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
      headers.set('Access-Control-Allow-Origin', '*');
      headers.set('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
      headers.set('Access-Control-Allow-Methods', 'GET,HEAD,PUT,PATCH,POST,DELETE,OPTIONS');
      headers.set('Access-Control-Allow-Credentials', 'true');
    
      if (token) {
        headers.set('authentication', `${token}`);
      }
      return headers;
    },
  });
 // const rawBaseQuery = fetchBaseQuery({ baseUrl });
  return rawBaseQuery(args, api, extraOptions);
};

// Backend Api
export const api = createApi({
  baseQuery: dynamicBaseQuery, // Use dynamic base query
  reducerPath: "adminApi",
  
  // tags
  tagTypes: [
    "User",
    "Products",
    "Customers",
    "Transactions",
    "Geography",
    "Sales",
    "Admins",
    "Performance",
    "Dashboard",
    "Category",
  ],
  // endpoints
  endpoints: (build) => ({
    adminLogin: build.mutation({
      query: ({ email, password }) => {
        const data = {
          email: email,
          password: password,
        };
        return {
          url: "auth/loginAdmin",
          method: "POST",
          body: JSON.stringify(data),
        };
      },
    }),
    getCategory: build.query({
      query: () => "admin/category/get",
      providesTags: ["Category"],
    }),
    deleteCategory: build.mutation({
      query: (categoryId) => ({
        url: `admin/category/${categoryId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Category"],
    }),
    getProductImages: build.mutation({
      query: (productId) => ({
        url: `admin/product/getImages/${productId}`,
        method: "GET",
      }),
      invalidatesTags: ["Category"],
    }),
    saveProductImage: build.mutation({
      query: ({ productId, image }) => {
        const formData = new FormData();
        formData.append("productId", productId);
        formData.append("file", image);
        return {
          url: "admin/product/saveImage",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Product"],
    }),
    createQuestions: build.mutation({
      query: ({ type, count }) => {
        return {
          url: "admin/question/generate",
          method: "POST",
          body: {
            "type" : type,
            "count" : count
        },
        };
      },
      invalidatesTags: ["Question"],
    }),
    createProductQuestions: build.mutation({
      query: ({ questionFormat, productId,questionCount }) => {
        return {
          url: "admin/product/createProductQuestion",
          method: "POST",
          body: {
            "questionFormat" : questionFormat,
            "productId" : productId,
            "questionCount":questionCount
        },
        };
      },
      invalidatesTags: ["Question"],
    }),
    getProductQuestions: build.mutation({
      query: (productId) => ({
        url: `admin/product/getProductQuestionsType/${productId}`,
        method: "GET",
      }),
      invalidatesTags: ["Category"],
    }),
    deleteProductQuestion: build.mutation({
      query: (id) => ({
        url: `admin/product/deleteProductQuestion/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Category"],
    }),
    deleteImage: build.mutation({
      query: (imageId) => ({
        url: `admin/product/deleteImage/${imageId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Category"],
    }),
    createCategory: build.mutation({
      query: ({ name, image }) => {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("file", image);
        return {
          url: "admin/category/create",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Category"],
    }),
    updateCategory: build.mutation({
      query: ({ name, image }) => {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("file", image);
        return {
          url: "admin/category/update",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Category"],
    }),
    getQuestions: build.query({
      query: () => "admin/question",
      providesTags: ["Product"],
    }),
    getProducts: build.query({
      query: () => "admin/product/get",
      providesTags: ["Product"],
    }),
    deleteProduct: build.mutation({
      query: (productId) => ({
        url: `admin/product/${productId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Product"],
    }),
    createProduct: build.mutation({
      query: ({ productName,
        productDescription,
        terms,
        categoryId,
        productPrice,
        totalWish,
        totalQuestions,
        status,
        image,deductingWish,productType,isFirstWinnerGotProduct,isPlayable,isTimeBasedEvent ,startDateTime,endDateTime }) => {
        const formData = new FormData();
       

        let utcDateStart;
        let utcDateEnd;
          
          if(isTimeBasedEvent === "0"){
             const date = new Date();
             utcDateStart = date.toISOString();
            const dateend = new Date();
            utcDateEnd = dateend.toISOString();
          }else{
            const date = new Date(startDateTime);
            utcDateStart = date.toISOString();
            const dateend = new Date(endDateTime);
            utcDateEnd = dateend.toISOString();
          }

        formData.append("productName", productName);
        formData.append("productDescription", productDescription);
        formData.append("terms", terms);
        formData.append("categoryId", categoryId);
        formData.append("productPrice", productPrice);
        formData.append("totalWish", totalWish);
        formData.append("totalQuestions", totalQuestions);
        formData.append("status", status);
        formData.append("file", image);
        formData.append("productType", productType);
        formData.append("deductingWish", deductingWish);
        formData.append("isFirstWinnerGotProduct", isFirstWinnerGotProduct);
        formData.append("isPlayable", isPlayable);
        formData.append("isTimeBasedEvent", JSON.stringify(isTimeBasedEvent === "0" ? false : true));
        formData.append("startDateTime", utcDateStart);
        formData.append("endDateTime", utcDateEnd);

        return {
          url: "admin/product/create",
          method: "POST",
          body:  formData,
         
        };
      },
      invalidatesTags: ["Product"],
    }),
    updateProduct: build.mutation({
      query: ({ productName,
        _id,
        productDescription,
        terms,
        categoryId,
        productPrice,
        totalWish,
        totalQuestions,
        status,
        image,
        productType,
        deductingWish,
        isFirstWinnerGotProduct,isPlayable,
        isTimeBasedEvent,startDateTime,endDateTime  }) => {
          let utcDateStart;
          let utcDateEnd;
          
          if(isTimeBasedEvent === "0"){
             const date = new Date();
             utcDateStart = date.toISOString();
            const dateend = new Date();
            utcDateEnd = dateend.toISOString();
          }else{
            const date = new Date(startDateTime);
            utcDateStart = date.toISOString();
            const dateend = new Date(endDateTime);
            utcDateEnd = dateend.toISOString();
          }
         

        return {
          url: `admin/product/update/${_id}`,
          method: "POST",
          body: {
            "productName" : productName,
            "productDescription" : productDescription,
            "terms":terms,
            "productPrice":productPrice,
            "totalWish":totalWish,
            "totalQuestions":totalQuestions,
            "status":status,
            "_id":_id,
            "productType" : productType,
            "deductingWish" : deductingWish,
            "isFirstWinnerGotProduct" : isFirstWinnerGotProduct,
            "isPlayable":isPlayable,
            "isTimeBasedEvent": isTimeBasedEvent === "0" ? false : true,
            "startDateTime": utcDateStart ?? "",
            "endDateTime" : utcDateEnd ?? "",
        },
        };
      },
      invalidatesTags: ["Product"],
    }),
    getUser: build.query({
      query: (id) => `general/user/${id}`,
      providesTags: ["User"],
    }),
    getCustomers: build.query({
      query: () => "client/customers",
      providesTags: ["Customers"],
    }),
    getTransactions: build.query({
      query: ({ page, pageSize, sort, search }) => ({
        url: "client/transactions",
        method: "GET",
        params: { page, pageSize, sort, search },
      }),
      providesTags: ["Transactions"],
    }),
    getGeography: build.query({
      query: () => "client/geography",
      providesTags: ["Geography"],
    }),
    getSales: build.query({
      query: () => "sales/sales",
      providesTags: ["Sales"],
    }),
    getAdmins: build.query({
      query: () => "management/admins",
      providesTags: ["Admins"],
    }),
    getUserPerformance: build.query({
      query: (id) => `management/performance/${id}`,
      providesTags: ["Performance"],
    }),
    getDashboard: build.query({
      query: () => "general/dashboard",
      providesTags: ["Dashboard"],
    }),
    createProductWinning: build.mutation({
      query: ({ winrange, productId,winpercent,amount }) => {
        return {
          url: "admin/product/createProductWinning",
          method: "POST",
          body: {
            "winrange" : winrange,
            "productId" : productId,
            "winpercent":winpercent,
            "amount":amount
        },
        };
      },
      invalidatesTags: ["Question"],
    }),
    getProductWinning: build.mutation({
      query: (productId) => ({
        url: `admin/product/getProductWinning/${productId}`,
        method: "GET",
      }),
      invalidatesTags: ["Category"],
    }),
    deleteProductWinning: build.mutation({
      query: (id) => ({
        url: `admin/product/deleteProductWinning/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Category"],
    }),
  }),
});

// export api endpoints
export const {
  useGetUserQuery,
  useGetProductsQuery,
  useGetCustomersQuery,
  useGetTransactionsQuery,
  useGetGeographyQuery,
  useGetSalesQuery,
  useGetAdminsQuery,
  useGetUserPerformanceQuery,
  useGetDashboardQuery,
  useGetCategoryQuery,
  useDeleteCategoryMutation,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useCreateProductMutation,
  useDeleteProductMutation,
  useUpdateProductMutation,
  useAdminLoginMutation,
  useSaveProductImageMutation,
  useGetProductImagesMutation,
  useDeleteImageMutation,
  useCreateQuestionsMutation,
  useGetQuestionsQuery,
  useCreateProductQuestionsMutation,
  useGetProductQuestionsMutation,
  useDeleteProductQuestionMutation,
  useCreateProductWinningMutation,
  useGetProductWinningMutation,
  useDeleteProductWinningMutation
} = api;
